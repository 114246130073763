@import './_media.scss';

/* Lista z ubezpieczeniami */

@import './ubezpieczenieLista.scss';

/* Konkretne ubezpieczenie z listy */

@import './ubezpieczenieStrona.scss';
$darkGreen: #2e7d32;
$darkGrey: #292a2a;
.header {
    border-bottom: 3px solid $darkGreen;
}

body.front {
    .region-blockgroup-ubexpieczenia-front {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        .block-imageblock {
            flex-basis: 24%;
            @include media('<=tablet') {
                flex-basis: 49%;
            }
            @include media('<=phone') {
                flex-basis: 100%;
            }
            .block_content {
                height: 370px;
                background: #fff;
                padding: 20px 20px 40px 20px;
                z-index: 998;
                position: relative;
                .block-title,
                .block-title a {
                    font-weight: bold;
                    text-transform: uppercase;
                    text-align: center;
                    color: #000;
                    margin-bottom: 10px;
                }
                .block-body {
                    min-height: 6em;
                }
                .front_block_link,
                .wycena_button a {
                    background: $darkGrey;
                    display: block;
                    text-align: center;
                    padding: 12px;
                    color: #fff;
                    text-decoration: none;
                    font-size: 1.3em;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 20px;
                    margin-left: auto;
                    margin-right: auto;
                    width: 80%;
                }
                .wycena_button a {
                    text-transform: uppercase;
                    background: $darkGreen;
                }
            }
        }
    }
}

.bg_image {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% auto;
    left: 0;
    right: 0;
    max-width: 1920px;
    margin: 0 auto;
    z-index: -1; //background-position: 0 center;
    //max-height: calc(100vh - 229px);
}

.region-bottom {
    background: #fff;
    padding: 30px;
    text-align: center;
    #block-webform-client-block-3 {
        .block_content {
            margin: 0 auto;
            max-width: 650px;
            .block-title {
                font-weight: 300;
                font-size: 2.2em;
            }
            .form-item label {
                font-weight: 300;
                text-align: center;
            }
            .form-submit {
                background: #81C784;
                background-size: 100% 100%;
                border: 0;
                margin-top: 20px;
                color: #fff;
                text-transform: uppercase;
                font-weight: bold;
                padding: 15px 30px;
            }
        }
    }
}

#content {
    #page-title {
        color: #000;
        text-transform: uppercase;
        margin-bottom: 10px;
    } //margin-bottom: 100px;
}

.region-bottom2 {
    color: #ccc !important;
    z-index: 9999;
    font-size: 1.1em;
    padding-top: 30px;
    >.content {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        .block_content,
        .block {
            margin: 0;
            padding: 0;
        }
        .block-title {
            color: #fff;
            border-bottom: 1px solid #fff;
            line-height: 1.7em;
            font-weight: normal;
            margin-bottom: 15px;
            font-size: 1.3em;
        }
        >div.block {
            //flex-basis: 30%;
            padding: 0 20px;
        }
        .block-search {
            display: flex;
            align-items: center;
            flex-grow: 1;
            justify-content: flex-end;
            .form-actions {
                margin-left: -3px;
            }
        }
        .block-menu {
            li {
                list-style-type: none;
                list-style-image: none;
            }
            .menu {
                margin: 0;
                -webkit-padding-start: 0px;
            }
            a:visited,
            a:link {
                color: #ccc; //text-transform: uppercase;
                text-decoration: none; //letter-spacing: 1px;
                &:hover {
                    color: $darkGreen;
                }
            }
        }
        #block-block-27 {
            flex-basis: 200px;
            display: flex;
            align-items: flex-start;
            p {
                font-size: 1em;
                margin: 0;
                line-height: 1.5em;
            }
        }
        >div:last-child {
            flex-basis: 100%;
            text-align: center;
            padding-top: 20px;
            p {
                font-size: .7em;
                margin: 0;
            }
        }
    }
}

@include media('<=tablet') {
    #block-block-4 p.button-ubezpiecz {
        padding: 3vw;
        a {
            font-size: 4vw;
            line-height: 4.5vw;
        }
    }
}