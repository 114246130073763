$darkGreen: #2e7d32;
$darkGrey: #292a2a;
body.node-type-page.section-ubezpieczenia-grupowe,
body.node-type-page.section-nnw-indywidualne,
body.node-type-page.section-ubezpieczenia-zdrowotne,
body.node-type-page.section-ubezpieczenia-na-zycie {
    #bg {
        background: none;
    }
    #content {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
        margin: 20px 0 100px;
        @include media('<=tablet') {
            margin: 20px 0;
        }
    }
    .content_content {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        @include media('<=phone') {
            padding-left: 0;
            padding-right: 0;
        }
        h1,
        ul {
            flex-basis: 100%;
        }
        h1.page__title {
            font-weight: 700;
            color: #000;
            text-transform: uppercase;
            margin: 10px 30px;
            font-size: 2.3em;
        }
        >.block {
            flex-basis: 70%;
            @include media("<=tablet") {
                flex-basis: 100%;
            }
        }
        >article {
            order: 9;
            display: block;
            #block-block-11,
            #block-block-12,
            #block-block-15,
            #block-block-16,
            #block-block-24 {
                position: sticky !important;
                width: 100%;
                right: 0;
                top: 20vh;
                flex-basis: 30%;
                font-size: 24px;
                p strong {
                    color: #000;
                }
                .wycena_podstrona a {
                    background: $darkGreen;
                    padding: 15px 20px;
                    color: #fff;
                    display: block;
                    text-decoration: none;
                    text-align: center;
                    font-weight: 400;
                    text-transform: uppercase;
                    font-size: 0.9em;
                    &:hover {
                        opacity: 0.9;
                    }
                }
            }
        }
    }
    .region-bottom {
        display: none;
    }
}

#block-views-samochod-block-1 .views-row,
#block-views-mieszkanie-block-1 .views-row,
#block-views-turystyka-block-1 .views-row,
#block-views-firmowe-block-1 .views-row,
#block-views-tu-block-1 .views-row {
    &.views-row-even {
        background: #fff;
    }
    padding: 40px;
    overflow: hidden;
    .views-field-title {
        padding-bottom: 10px;
        border-bottom: 1px solid $darkGreen;
        a {
            color: $darkGreen;
            font-weight: 400;
            .title_logotu {
                display: flex;
                justify-content: space-between;
                .title {
                    line-height: 1em;
                    align-self: flex-end;
                }
                .logotu {
                    img {
                        max-width: 110px;
                        max-height: 40px;
                    }
                }
            }
        }
    }
    .views-field-field-miniatura {
        @include media('<=phone') {
            clear: both;
            float: none;
            margin: 0;
        }
        img {
            width: 250px;
            @include media('<=phone') {
                width: 100%;
                margin: 0;
            }
        }
    }
    .views-field-field-skr-t {
        ul {
            margin: 0;
        }
    }
}