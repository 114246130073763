$darkGreen: #2e7d32;
$darkGrey: #292a2a;
@mixin articlecontent($mainColor, $secondaryColor: #000) {
    h3.active {
        background: $mainColor !important;
    }
    #collapsible a.cennik {
        background: $mainColor;
    }
    .article_content_addons_inside {
        p strong {
            color: $secondaryColor;
        }
        .wycena_podstrona a {
            background: $mainColor;
        }
    }
    .field-name-field-podobne-produkty .field-item a {
        &:before {
            background: $secondaryColor;
        }
        &:hover {
            color: $mainColor;
        }
    }
    #collapsible {
        h3 {
            position: relative;
            &:after {
                content: '+';
                display: block;
                position: absolute;
                top: 0;
                right: 20px;
                line-height: 49px;
                font-size: 25px;
            }
            &.active:after {
                content: '-';
            }
            &:first-of-type:last-of-type {
                display: none;
            }
        }
        h4,
        h2,
        table.tabela_ubezp th {
            color: $secondaryColor;
            background: none;
        }
        table.tabela_ubezp th {
            border-bottom: 2px solid $mainColor;
        }
        li:before {
            border-color: $secondaryColor;
            background: $secondaryColor;
        }
        table.zwrot-na-koniec,
        table.wiek-zawierania {
            thead {
                border-color: $mainColor;
            }
        }
    }
}

body.node-type-strona-na-zycie,
body.node-type-strona-nnw-indywidualne,
body.node-type-strona-grupowe,
body.node-type-strona-zdrowotne {
    font-size: 1.1em;
    h1.page__title {
        display: none;
    }
    height: 100vh;
    &.admin-menu {
        height: calc(100vh - 29px);
    }
    .region-bottom {
        display: none;
    }
    #bg {
        background: none;
    }
    #page {
        min-height: calc(100vh - 229px);
    }
    #content {
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
        margin: 20px 0 100px;
        @include media('<=tablet') {
            margin: 20px 0;
        }
    }
    #collapsible {
        margin-bottom: 20px;
        >div {
            padding: 10px;
            h2 {
                margin: 10px 0;
                font-size: 1.7em;
                text-align: center;
                color: #000000;
            }
        }
        table.tabela_ubezp {
            tr {
                td {
                    padding: 20px;
                    font-size: 1.1em;
                    font-weight: 600;
                    &:first-of-type {
                        width: 60px;
                        text-align: center;
                    }
                    img {
                        width: auto !important;
                        height: auto !important;
                        max-height: 55px;
                    }
                }
            }
            tr:nth-child(even) {
                background: #f4f6f4;
                ;
            }
        }
        h4 {
            margin: 20px 0 0 0;
            font-size: 1.2em;
        }
        a.cennik {
            color: #fff;
            padding: 10px 30px;
            text-decoration: none;
            text-align: center;
            &:hover {
                opacity: 0.8;
            }
        }
        ul {
            list-style: none;
            display: table;
            margin: 10px 0;
            li {
                margin: 5px 0;
                &:before {
                    content: '';
                    display: inline-block;
                    width: .3em;
                    height: .3em;
                    margin-left: -1em;
                    margin-right: 5px;
                    border: 1px solid;
                    margin-bottom: .1em;
                }
            }
        }
        .ikony {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            margin: 20px 0;
            .ikona {
                flex-basis: 25%;
                text-align: center;
                margin-bottom: 15px;
                @include media('<=tablet') {
                    flex-basis: 50%;
                }
                @include media('<=phone') {
                    flex-basis: 100%;
                }
                img {
                    padding: 10px;
                    height: 70px !important;
                    width: auto !important;
                }
                div {
                    max-width: 90%;
                }
            }
        }
    }
    .field-name-field-film {
        margin-top: 30px;
        .field-item {
            margin: 20px 0;
            .embedded-video .player {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
                max-width: 100%;
            }
            .embedded-video .player iframe,
            .embedded-video .player object,
            .embedded-video .player embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .video-embed-description {
                font-size: 1.2em;
                font-weight: 600;
                text-align: center;
                padding: 10px;
                background: #E9E9E9;
                color: #000;
            }
        }
    }
    #block-block-11,
    #block-block-12,
    #block-block-15,
    #block-block-16,
    #block-block-24 {
        width: auto !important;
        flex-basis: 30%;
        font-size: 24px;
        text-align: center;
        .block-wycena-wrapper {
            margin-top: 20px;
            .wycena_podstrona a {
                padding: 15px 20px;
                color: #fff;
                display: block;
                text-decoration: none;
                text-align: center;
                font-weight: 400;
                text-transform: uppercase;
                font-size: 0.9em;
                &:hover {
                    opacity: 0.9;
                }
            }
        }
        @include media('<=tablet') {
            order: 9;
            right: 0 !important;
            margin: auto;
        }
    }
    article {
        @include articlecontent($darkGreen, $darkGreen);
        &.warta {
            @include articlecontent(#06315b, #b40039);
        }
        &.nationale-nederlanden {
            @include articlecontent(#ef9c00);
            p {
                margin-top: .2em;
            }
            #collapsible table.tabela_ubezp tr td {
                font-weight: normal;
                ul {
                    margin-left: 30px;
                }
            }
        }
        &.generali {
            @include articlecontent(#bc171a);
        }
        &.compensa {
            @include articlecontent(#008743, #df0024);
            #collapsible table.tabela_ubezp {
                th {
                    padding: 10px 5px;
                }
                tr td {
                    padding: 5px;
                    font-weight: normal;
                    font-size: 0.9em;
                    text-align: center;
                    &:first-of-type {
                        width: auto;
                        text-align: left;
                    }
                }
            }
        }
        &.axa {
            @include articlecontent(#000090, #ff1822);
        }
        h1.fixed-page-title {
            color: #000;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 10px;
            font-weight: 700;
            text-transform: uppercase;
            margin: 10px;
            font-size: 2.1em;
        }
        .article_content {
            display: flex;
            @include media('<=tablet') {
                flex-wrap: wrap;
            }
            justify-content: space-between;
            .article_content_main {
                flex-basis: 70%;
                @include media("<=tablet") {
                    flex-basis: 100%;
                }
            }
            .article_content_addons {
                h1 {
                    display: none;
                }
                flex-basis: 28%;
                @include media('<=tablet') {
                    flex-basis: 100%;
                }
                .article_content_addons_inside {
                    margin-top: 70px;
                    position: sticky !important;
                    top: 10vh;
                    height: auto;
                    padding: 0 30px;
                    @include media('<=tablet') {
                        position: relative;
                    }
                    .field-name-field-tu {
                        text-align: center;
                        margin: 15px 30px 30px 30px;
                        .article_content_addons,
                        .node-title,
                        ul.links {
                            display: none;
                            visibility: hidden;
                        }
                        .article_content_main {
                            flex-basis: 100%;
                        }
                        .field-name-field-miniatura {
                            display: block;
                            margin: 0 auto;
                            width: 100%;
                        }
                    }
                    .field-name-field-podobne-produkty {
                        .field-label {
                            font-size: 1.4em;
                            border-bottom: 1px solid $darkGrey;
                            margin-bottom: 10px;
                            padding-bottom: 5px;
                            font-weight: normal;
                        }
                        .field-item a {
                            text-decoration: none;
                            font-size: 1.1em;
                            line-height: 1.7em;
                            &:before {
                                content: '';
                                width: 8px;
                                height: 8px;
                                display: inline-block;
                                margin-right: 10px;
                                margin-left: 5px;
                                margin-bottom: 1px;
                            }
                        }
                    }
                }
            }
        }
        table.zwrot-na-koniec,
        table.wiek-zawierania {
            border: 0;
            * {
                border: 0;
            }
            thead {
                border-bottom: 3px solid;
            }
            tr:not(:last-of-type) {
                border-bottom: 1px solid #616161;
            }
            td,
            th {
                padding: 5px 10px;
                vertical-align: middle;
                &:not(:first-of-type) {
                    text-align: center;
                }
                p {
                    margin: 0;
                }
            }
        }
    }
    .back-to-list {
        text-decoration: none;
    }
}